import React, { useState } from 'react'
import SubSubmenuItem from './SubSubmenuItem'
import { Box, Grid, List, ListItemButton, ListItemText } from '@mui/material'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function SubmenuItem({ openStatus, item, title }) {
    const sideBarStatus = useSelector((state) => state.global.sideBarIsOpen);

    return (
        <List component="ul" className="sub-menu" style={openStatus ? { display: 'block' } : {}}>
            {sideBarStatus &&
                <ListItemButton component='a' href="#">
                    <ListItemText sx={{ pl: 2, fontSize: 15, color: 'text.main' }}>
                        {title}
                    </ListItemText>
                </ListItemButton>
            }

            {item && item.map((item, index) =>
                <>
                    {item.childNavigations.length > 0 ?
                        <SubSubmenuItem
                            item={item.childNavigations}
                            title={item.name}
                        />
                        :

                        <Box component="li">
                            <ListItemButton component={Link} to={`${item.route}`}  >
                                <Grid container sx={{ pl: 4 }}>
                                    <Grid item xs={8}>
                                        <ListItemText sx={{ color: 'text.main' }} primary={item.name} />
                                    </Grid>
                                    <Grid item xs={4}>

                                    </Grid>
                                </Grid>
                            </ListItemButton>

                        </Box>
                    }

                </>
            )}
        </List>
    )

}

export default SubmenuItem