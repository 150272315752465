import { configureStore } from '@reduxjs/toolkit'
import globalReducer from './globalSlice'
import todoReducer from "./slice/todo";
import searchBookingReducer from "./slice/searchBooking";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    todo: todoReducer,
    searchBooking: searchBookingReducer,
  },
})