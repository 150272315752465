import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Link, CircularProgress, Modal, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText, ListItemButton, Divider, Menu, Card, CardContent, IconButton, FormControl, InputLabel, Select, MenuItem, ButtonGroup } from "@mui/material";
import Icons from '../Master/Icons';
import { SettingsSystemDaydreamRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { addSearchModel, delSearchModel } from '../../slice/searchBooking';

const AddRemoveLocation = ({ index, orientation = "vertical" }) => {
    const dispatch = useDispatch();
    return (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", margin: "2px", backgroundColor: '#eee', borderRadius: "8px" }}  >
            <ButtonGroup size="small" orientation={orientation} aria-label="small outlined button group">
                <Button startIcon={<Icons icon="AddIcon" />} onClick={() => dispatch(addSearchModel())} >Add City</Button>
                {index > 1 && <Button startIcon={<Icons icon="DeleteIcon" />} onClick={() => dispatch(delSearchModel())} >Reomve</Button>}
            </ButtonGroup>
        </Box >
    );
}

export { AddRemoveLocation };
