
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Box, AppBar, Toolbar, IconButton, InputBase, LinearProgress } from '@mui/material';
import Icons from './Icons';

import { useDispatch, useSelector } from 'react-redux';
import { resetSidebar } from '../../globalSlice'
import RightSidebar from './RightSidebar';
import Profile from './Profile';


const CustomeAppbar = styled(AppBar)(({ leftvalue }) => ({
  position: 'relative',
  backgroundColor: 'white',
  color: 'black',
  width: window.innerWidth - leftvalue
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function Navbar() {
  const leftWidth = useSelector((state) => state.global.leftWidth);
  const sideBarStatus = useSelector((state) => state.global.sideBarIsOpen);
  let loaderStatus = useSelector((state) => state.global.loadingSataus);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rightSidebarState, setRightSidebarState] = React.useState(false);
  const profileOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const sideBarToggol = () => {
    if (sideBarStatus !== null) {
      dispatch(resetSidebar(!sideBarStatus))
    } else {
      dispatch(resetSidebar(false))
    }
    // 
  }


  const menuId = 'primary-search-account-menu';

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'primary.white', boxShadow: 3 }}>
      {/* <CustomeAppbar leftvalue={leftWidth} > */}
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{ mr: 2 }}
          onClick={() => {
            dispatch(resetSidebar())

          }
          }
        >
          <Icons icon="MenuIcon" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Search style={{ backgroundColor: '#ddd' }}>
          <SearchIconWrapper>
            {/* <SearchIcon /> */}
            <Icons icon="SearchIcon" />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <Icons icon="AccountCircle" />
          </IconButton>

          <IconButton
            size="large"
            edge="end"
            aria-label="setting"
            aria-haspopup="true"
            onClick={() => setRightSidebarState(true)}
            color="inherit"
          >
            <Icons icon="SettingsOutlined" />
          </IconButton>
        </Box>
      </Toolbar>
      {/* </CustomeAppbar> */}
      <Profile menuId={menuId} anchorEl={anchorEl} profileOpen={profileOpen} handleMenuClose={handleMenuClose} />
      <RightSidebar state={rightSidebarState} setState={setRightSidebarState} />
      {loaderStatus && <LinearProgress />}
    </Box >
  );
}