import { Box, Button, TextField, Typography, Link, CircularProgress, Modal, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText, ListItemButton, Divider, Menu, Card, CardContent, AppBar, Container, Toolbar, Tooltip, IconButton, Avatar } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Icons from './Icons';
import anis from '../../assets/images/anis.png'
import Profile from "./Profile";
import { useState } from "react";

const Brand = () => {
    const navigate = useNavigate();
    const companyInfo = useSelector((state) => state.global.companyInfo);
    return (
        <Link href="#" onClick={() => navigate("/")} >
            <Box display="flex" justifyContent="center" alignItems="start">
                <Box display="flex" flexDirection={"column"} style={{ backgroundColor: '#000', justifyContent: "center", alignItems: "center", borderRadius: '8px' }} width={120} >
                    <Box display="flex" justifyContent="center" alignItems="center" height={50}>
                        <img src={companyInfo.logo} alt={companyInfo.name} width={"50"} />
                    </Box>
                    <Typography variant="h7" color="primary" style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center', borderRadius: '2px' }}  >
                        {companyInfo.name}
                    </Typography>
                    <Box height="10px"></Box>
                </Box>
            </Box>
        </Link>
    );
}

const Header = () => {
    const navigate = useNavigate();
    const hendleLogin = () => {
        navigate("/login");
    }

    return (
        <Box display="flex" flexDirection="row" sx={{ px: 1 }} >
            <Brand />
            <Box flexGrow={1}></Box>
            <Box display="flex" justifyContent="center" alignItems="center" >
                <Button onClick={hendleLogin} variant="contained" color="primary" startIcon={<Icons icon="LogoutIcon" />} >LOGIN</Button>
            </Box>
        </Box>
    );
}

const HeaderMobile = () => {
    const navigate = useNavigate();
    const hendleLogin = () => {
        navigate("/login");
    }

    return (
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ px: 1 }} >
            <Brand />
            <Box display="flex" justifyContent="center" alignItems="center" >
                <Button onClick={hendleLogin} variant="contained" color="primary" startIcon={<Icons icon="LogoutIcon" />} >LOGIN</Button>
            </Box>
        </Box>
    );
}


const Appbar = () => {
    const navigate = useNavigate();
    const companyInfo = useSelector((state) => state.global.companyInfo);
    const hendleLogin = () => {
        navigate("/login");
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const profileOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const menuId = 'primary-search-account-menu';

    return (
        <AppBar position="static">
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Box display="flex" justifyContent="start" alignItems="center" >
                    <Box display="flex" alignItems="center" height={50} sx={{ padding: "4px" }} >
                        <img src={companyInfo.logo} alt={companyInfo.name} width={"50"} />
                    </Box>
                    <Typography variant="h7" color="primary" style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center', borderRadius: '2px' }}  >
                        {companyInfo.name}
                    </Typography>
                </Box>
                <Box flexGrow={1}></Box>
                <Tooltip title="Open settings">
                    <IconButton
                        aria-controls={menuId}
                        onClick={handleProfileMenuOpen}>
                        <Avatar alt="Remy Sharp" src={anis} />
                    </IconButton>
                </Tooltip>
                <Profile menuId={menuId} anchorEl={anchorEl} profileOpen={profileOpen} handleMenuClose={handleMenuClose} />
            </Box>
        </AppBar>
    );

}

export { Header, Brand, Appbar, HeaderMobile };


