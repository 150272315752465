import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Link, CircularProgress, Modal, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText, ListItemButton, Divider, Menu, Card, CardContent, AppBar, Container, Toolbar, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { setLogin, setRoute, setDialogStatus, setDialogMessage, setLoader, setDialogLoder } from '../../globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import bg_accounts from '../../assets/images/bg_accounting.png';
import Icons from '../Master/Icons';
import urlPath from '../../constants/path/urlPath';
import logo from '../../assets/images/logo.png';
import Navbar from '../Master/Navbar';

import { SelectAutocomplete, LabelButton } from '../../Utlity/selectAutocomplete/selectAutocomplete';
import { SelectTraveler } from './selectTraveler';
import { AddRemoveLocation } from './AddRemoveLocation';
import { SearchBuliderMobile } from './SearchBulider';
import { Label } from '@mui/icons-material';
import { setWay, setFrom, setTo, setJournyDate, setReturnDate, setTraveler, addSearchModel, resetSearchModel, setSearchResult } from '../../slice/searchBooking';
import { Appbar, Header } from '../Master/Header';
import { FooterMobile } from '../Master/Footer';

const StyledBox = styled(Box)({
    width: "100%",
    backgroundColor: '#fff',
    color: "#000",
    // padding: "16px",
    borderRadius: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
});


const Mobile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const companyInfo = useSelector((state) => state.global.companyInfo);
    const isLogin = useSelector((state) => state.global.isLogin);
    const routes = useSelector((state) => state.global.routes);
    const way = useSelector((state) => state.searchBooking.way);
    const searchModel = useSelector((state) => state.searchBooking.searchModel);
    const searchResult = useSelector((state) => state.searchBooking.searchResult);
    const [cheapest, setCheapest] = React.useState("Cheapest");
    const [search, setSearch] = React.useState(false);


    useEffect(() => {
        if (way != "multi") {
            dispatch(resetSearchModel());
        }

        if (way == "multi" && searchModel.length < 2) {
            dispatch(addSearchModel());
        }

    }, [way]);


    const haldelSearch = () => {
        dispatch(setDialogLoder(true));
        dispatch(setSearchResult(airSchedule));
        setTimeout(() => {
            dispatch(setDialogLoder(false));
            setSearch(true);
        }, 6000);
    }

    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="center"  >
                <Appbar />                
                <StyledBox>
                    <Box display="flex" justifyContent="center" alignItems="center"  >
                        <RadioGroup row value={way} onChange={(event) => dispatch(setWay(event.target.value))}>
                            <FormControlLabel sx={{ m: 0 }} value="one" control={<Radio size="small" />} label="One Way" />
                            <FormControlLabel sx={{ m: 0 }} value="round" control={<Radio size="small" />} label="Round Way" />
                            <FormControlLabel sx={{ m: 0 }} value="multi" control={<Radio size="small" />} label="Multi City" />
                        </RadioGroup>
                    </Box>
                    {searchModel.map((item, i) =>
                        <Box display="flex" flexDirection="column" key={i} sx={{ m: 1 }}  >
                            <Box display="flex" sx={{ borderRadius: "8px", backgroundColor: '#eee' }}>
                                <SelectAutocomplete width={160} widthList={300} list={airPorts} model={item.from} index={i} setModel={setFrom} />
                                <Box sx={{ width: "1px", backgroundColor: '#fff' }}></Box>
                                <SelectAutocomplete width={160} widthList={300} list={airPorts} model={item.to} index={i} setModel={setTo} />
                            </Box>
                            <Box height={2}></Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <Box display="flex" sx={{ borderRadius: "8px", backgroundColor: '#eee', padding: '10px' }}>
                                    <Box width={145} display="flex" justifyContent="center" alignItems="center" >
                                        <DatePicker
                                            label="Journy Date"
                                            format="DD/MM/YYYY"
                                            value={item.journyDate}
                                            onChange={(newValue) => dispatch(setJournyDate({ index: i, data: newValue }))}

                                        />
                                    </Box>
                                    <Box sx={{ width: "1px", backgroundColor: '#fff' }}></Box>
                                    <Box width={145} display="flex" justifyContent="center" alignItems="center" >
                                        {way == "round" && <DatePicker
                                            label="Return Date"
                                            format="DD/MM/YYYY"
                                            defaultValue={item.journyDate} disablePast
                                            value={item.returnDate}
                                            onChange={(newValue) => dispatch(setReturnDate({ index: i, data: newValue }))}
                                        />}
                                    </Box>
                                </Box>
                            </LocalizationProvider>
                            <Box height={2}></Box>
                            {i == 0 && <SelectTraveler width={130} model={item.traveler} index={i} />}

                            {i > 0 && i == searchModel.length - 1 &&
                                <Box display="flex" sx={{ borderRadius: "8px", backgroundColor: '#eee', p: 1 }}>
                                    <AddRemoveLocation model={item.traveler} index={i} orientation='horizontal' />
                                </Box>
                            }

                        </Box>
                    )}
                    <Box height={10} ></Box>
                    <Box display="flex" >
                        <Box flexGrow={1}></Box>
                        <Box display="flex" justifyContent="center" alignItems="center" >
                            <Button variant="contained" color="primary" startIcon={<Icons icon="Search" />} onClick={haldelSearch}  > {search && "MODIFY"} SEAECH</Button>
                        </Box>
                        <Box flexGrow={1}></Box>
                    </Box>
                    <Box height={10}></Box>
                </StyledBox>

                <Box height={10}>&nbsp;</Box>

                {search &&
                    <StyledBox>
                        <Box display="flex" flexDirection={"column"} sx={{ borderRadius: "8px" }}>
                            <LabelButton width={467} setModel={() => setCheapest("Cheapest")} isBold={cheapest == "Cheapest" ? false : true} model={{ "code": "Cheapest", "name": (cheapest != "Cheapest" ? "Click to see " : "Showing ") + "the cheapest flights in ascending order" }} />
                            <Box sx={{ width: "1px", backgroundColor: '#fff' }}></Box>
                            <LabelButton width={467} setModel={() => setCheapest("Fastest")} isBold={cheapest == "Fastest" ? false : true} model={{ "code": "Fastest", "name": (cheapest == "Cheapest" ? "Click to see " : "Showing ") + "the fastest flights in ascending order" }} />
                        </Box>
                    </StyledBox>}

                <Box height={10}>&nbsp;</Box>

                {search && airSchedule && airSchedule.map((item, i) =>
                    <>
                        <StyledBox>
                            <SearchBuliderMobile item={item} i={i} />
                        </StyledBox>
                        <Box height={10}>&nbsp;</Box>
                    </>
                )}

                <Box height={10}>&nbsp;</Box>
                <StyledBox>
                    <FooterMobile />
                </StyledBox>
            </Box >
        </>
    );

}

export { Mobile };




const airPorts = [
    { code: "Dhaka", name: "DAC, Hazrat Shahjalal International Air", airPort: "DAC" },
    { code: "Chittagonj", name: "CTG, Hazrat Shahjalal International Air", airPort: "CTG" },
    { code: "Cox-Bazar", name: "COX, Hazrat Shahjalal International Air", airPort: "COX" },
    { code: "Kolkata", name: "Kal, Hazrat Shahjalal International Air", airPort: "Kal" },
    { code: "India", name: "Ind, Hazrat Shahjalal International Air", airPort: "Ind" },
    { code: "Dhaka1", name: "DAC, Hazrat Shahjalal International Air", airPort: "DAC" },
    { code: "Dhaka2", name: "DAC, Hazrat Shahjalal International Air", airPort: "DAC" },
    { code: "Dhaka3", name: "DAC, Hazrat Shahjalal International Air", airPort: "DAC" },
    { code: "Dhaka4", name: "DAC, Hazrat Shahjalal International Air", airPort: "DAC" },
    { code: "Dhaka5", name: "DAC, Hazrat Shahjalal International Air", airPort: "DAC" },
]

const airSchedule = [
    { flightNo: "EDOM1123", fairReduce: "4,991", fairRegular: "5,699", currency: "BDT", totalTime: "1h 5m", layover: [{ description: "2h 5m layover at MCT", airPort: "MCT" }, { description: "2h 5m layover at MCT", airPort: "MCT" }], endLocation: { time: "09:20", airPort: "CXB" }, startLocation: { time: "08:15", airPort: "DAC" }, airName: "Biman Bangladesh Airlines", airImg: "https://storage.googleapis.com/gz-flight-prod-booking-data/carrier/logo/d31c6c3d-505d-4675-9b02-ecd9c2301115.png" },
    { flightNo: "DUYB1190", fairReduce: "4,991", fairRegular: "5,699", currency: "BDT", totalTime: "1h 5m", layover: [], endLocation: { time: "09:20", airPort: "CXB" }, startLocation: { time: "08:15", airPort: "DAC" }, airName: "Biman Bangladesh Airlines", airImg: "https://storage.googleapis.com/gz-flight-prod-booking-data/carrier/logo/d31c6c3d-505d-4675-9b02-ecd9c2301115.png" },
    { flightNo: "UHJH521", fairReduce: "4,991", fairRegular: "5,699", currency: "BDT", totalTime: "1h 5m", layover: [{ description: "2h 5m layover at MCT", airPort: "MCT" }, { description: "2h 5m layover at MCT", airPort: "MCT" }], endLocation: { time: "09:20", airPort: "CXB" }, startLocation: { time: "08:15", airPort: "DAC" }, airName: "Turkish Airlines", airImg: "https://storage.googleapis.com/gz-flight-prod-booking-data/carrier/logo/12acdd01-927a-46aa-bb1b-71b74061f9a9.png" },
    { flightNo: "LKIJ9098", fairReduce: "4,991", fairRegular: "5,699", currency: "BDT", totalTime: "1h 5m", layover: [{ description: "2h 5m layover at MCT", airPort: "MCT" }], endLocation: { time: "09:20", airPort: "CXB" }, startLocation: { time: "08:15", airPort: "DAC" }, airName: "Biman Bangladesh Airlines", airImg: "https://storage.googleapis.com/gz-flight-prod-booking-data/carrier/logo/d31c6c3d-505d-4675-9b02-ecd9c2301115.png" },
    { flightNo: "KHYBJN9787", fairReduce: "4,991", fairRegular: "5,699", currency: "BDT", totalTime: "1h 5m", layover: [{ description: "2h 5m layover at MCT", airPort: "MCT" }, { description: "2h 5m layover at MCT", airPort: "MCT" }, { description: "2h 5m layover at MCT", airPort: "MCT" }], endLocation: { time: "09:20", airPort: "CXB" }, startLocation: { time: "08:15", airPort: "DAC" }, airName: "Biman Bangladesh Airlines", airImg: "https://storage.googleapis.com/gz-flight-prod-booking-data/carrier/logo/d31c6c3d-505d-4675-9b02-ecd9c2301115.png" },
    { flightNo: "JHYBss", fairReduce: "4,991", fairRegular: "5,699", currency: "BDT", totalTime: "1h 5m", layover: [{ description: "2h 5m layover at MCT", airPort: "MCT" }], endLocation: { time: "09:20", airPort: "CXB" }, startLocation: { time: "08:15", airPort: "DAC" }, airName: "Qatar Airways", airImg: "https://storage.googleapis.com/gz-flight-prod-booking-data/carrier/logo/c46cb45b-c673-4439-86e0-532fe00ffcc2.png" },
    { flightNo: "EDOM1123", fairReduce: "4,991", fairRegular: "5,699", currency: "BDT", totalTime: "1h 5m", layover: [{ description: "2h 5m layover at MCT", airPort: "MCT" }, { description: "2h 5m layover at MCT", airPort: "MCT" }], endLocation: { time: "09:20", airPort: "CXB" }, startLocation: { time: "08:15", airPort: "DAC" }, airName: "Biman Bangladesh Airlines", airImg: "https://storage.googleapis.com/gz-flight-prod-booking-data/carrier/logo/d31c6c3d-505d-4675-9b02-ecd9c2301115.png" },
    { flightNo: "EDOM1123", fairReduce: "4,991", fairRegular: "5,699", currency: "BDT", totalTime: "1h 5m", layover: [{ description: "2h 5m layover at MCT", airPort: "MCT" }, { description: "2h 5m layover at MCT", airPort: "MCT" }], endLocation: { time: "09:20", airPort: "CXB" }, startLocation: { time: "08:15", airPort: "DAC" }, airName: "Biman Bangladesh Airlines", airImg: "https://storage.googleapis.com/gz-flight-prod-booking-data/carrier/logo/d31c6c3d-505d-4675-9b02-ecd9c2301115.png" }
]