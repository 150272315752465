import { Box, CircularProgress } from '@mui/material'
import React, { useRef } from 'react'

function CircularLoader() {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    return (
        <Box sx={{ backgroundColor: '#11101d46', position: 'fixed', top: 0, zIndex: 10000, width: '100%', height: windowSize.current[1], display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ backgroundColor: 'primary.white', height: 80, width: 80, justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '5px', boxShadow: 2 }}>
                <CircularProgress />
            </Box>
        </Box>
    )
}

export default CircularLoader