import React from 'react'
import './Master.css'
import SideBarContainer from '../SideBar/SideBarContainer'
import Navbar from './Navbar';
import { Box, CircularProgress, Snackbar } from "@mui/material";
import AlertDialog from '../dialog/AlertDialog';
import { useSelector } from 'react-redux';
import CustomizedSnackbars from '../../Utlity/snackBar/customSnackBar';
import CircularLoader from '../../Utlity/loaderBuffer/CircularLoader';


function Web(props) {
    let circularLoder = useSelector((state) => state.global.circularLoder);


    return (
        <>
            {/* <div className="sidebar"> */}
            <SideBarContainer />
            <Box className="home-section" >
                <div className="home-content">
                    <Navbar />
                </div>
                {props.children}
                {/* <Snackbar
                    open={true}
                    autoHideDuration={6000}
                    // onClose={handleClose}
                    message="Note archived"
                // action={action}
                /> */}

            </Box>
            {circularLoder && <CircularLoader />}
            {<CustomizedSnackbars />}

        </>
    );
}

export default Web