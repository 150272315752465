import { Box, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React, { useState } from 'react'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import BuildIcon from '@mui/icons-material/Build';
import { Link } from 'react-router-dom';
import Icons from '../Master/Icons';

function SubSubmenuItem({ item, title }) {
    const [subSubOpen, setSubSubOpen] = useState(false)
    const subSubtoggol = () => {
        setSubSubOpen(!subSubOpen)
    }

    return (
        <>

            <Box component="li">
                <ListItemButton component='a' href="#" onClick={subSubtoggol} >
                    <Grid container sx={{ pl: 4 }}>
                        <Grid xs={2}>
                            <Icons icon={item.icon} />
                            {/* <BuildIcon sx={{ color: 'text.main' }} fontSize='small' /> */}
                        </Grid>
                        <Grid item xs={8}>

                            <ListItemText sx={{ color: 'text.main' }} primary={title} />
                        </Grid>
                        <Grid item xs={2}>
                            {subSubOpen ? <ExpandMore sx={{ color: 'text.main' }} /> : <ExpandLess sx={{ color: 'text.main' }} />}
                        </Grid>
                    </Grid>

                </ListItemButton>

            </Box>
            <List component="ul" className={`sub-sub-menu${!subSubOpen ? '-close' : '-open'}`}>

                {item && item.map((item, index) =>
                    <>

                        <ListItemButton component={Link} to={`${item.route}`}>
                            <ListItemIcon>
                                {/* <SendIcon /> */}
                            </ListItemIcon>
                            <Typography
                                sx={{ display: 'inline', pl: 3, justifyContent: 'center', alignItems: 'center' }}
                                component="span"
                                color="text.main"

                            >
                                {/* <SendIcon fontSize={'10px'} sx={{ pr: 1 }} /> */}
                                {item.name}
                            </Typography>

                        </ListItemButton>
                    </>
                )}
            </List>
        </>
    )
}

export default SubSubmenuItem