import React from "react";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate, Router } from "react-router-dom";
import Pages from './Pages'
import { useDispatch, useSelector } from 'react-redux'
import { resetSidebar, setSidebar, setRoute, setToken, setLogin, setDialogMessage } from '../globalSlice'

import axios from 'axios';
import { Layout } from "./Login/Layout";
// axios.defaults.baseURL = "http://172.29.50.32:5000/";
// axios.defaults.baseURL = "http://172.29.50.21/aarongportal/";
axios.defaults.baseURL = "http://localhost:5130/";


axios.defaults.headers =
{
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

// let token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiODE0OTQiLCJMb2dpbklkIjoiODE0OTQiLCJVc2VySWQiOiIxIiwiVXNlck5hbWUiOiJhbmlzIiwibmJmIjoxNjg4NjE1NjA4LCJleHAiOjE2ODg3MDIwMDgsImlhdCI6MTY4ODYxNTYwOH0.bCtc4ThH8VxH3awfdCgDJejSOHCN1NRH0Jw9tzfpYyU`
// axios.interceptors.request.use(function (config) {
//     config.headers.Authorization = token ? `Bearer ${token}` : "";
//     return config;
// });


const anonymousRoutes = [
    {
        name: "welcomePage",
        route: '/',
        location: 'None',
    }, {
        name: "Login",
        route: '/login',
        location: 'None',
    }, {
        name: "Sign Up",
        route: '/sign-up',
        location: 'None',
    }, {
        name: "Registration",
        route: '/registration',
        location: 'None',
    }, {
        name: "Forget Password",
        route: '/forget-password',
        location: 'None',
    }, {
        name: "Construction",
        route: '/construction',
        location: 'None',
    }, {
        name: "booking",
        route: '/booking',
        location: 'None',
    },
];

const getToken = () => {
    const tokenString = localStorage.getItem('user');
    const userToken = JSON.parse(tokenString);
    // return userToken == null || userToken.access_token == null ? "" : userToken.access_token;
    return userToken == null || userToken.jwtToken == null ? "" : userToken.jwtToken;
};

const getAppRouter = (items) => {

    var outItems = [];
    if (items.length > 0) {
        outItems.push({
            name: "home",
            route: '/home',
            location: 'None',
        });
    }
    return outItems;
};

const getRoute = () => {
    const userProfileTemplateString = localStorage.getItem('userProfileTemplate');
    const userProfileTemplate = JSON.parse(userProfileTemplateString);
    // const userProfileTemplate = null;
    // return userProfileTemplate == null || userProfileTemplate.UserMenuList == null ? anonymousRoutes : getAppRouter(userProfileTemplate.UserMenuList).concat(anonymousRoutes);
    return userProfileTemplate == null ? anonymousRoutes : userProfileTemplate.concat(anonymousRoutes);
};

const getAcitveRotue = (routes) => {

    // console.log('router___', routes)
    let returnRoute = [];

    const getItemRoute = (items) => {
        items.map((item) => {
            if (item.route != null && item.route != '') {
                returnRoute.push(item);
            }
            if (item.childNavigations != null && item.childNavigations.length > 0) {
                getItemRoute(item.childNavigations);
            }
        });
    }

    routes.map((item) => {
        if (item.route != null && item.route != '') {
            returnRoute.push(item);
        }
        if (item.childNavigations != null && item.childNavigations.length > 0) {
            getItemRoute(item.childNavigations);
        }
    });

    return returnRoute;
}

const Main = () => {
    const routes = useSelector((state) => state.global.routes);
    const trempRoutes = getRoute();
    const activeRoutes = getAcitveRotue(trempRoutes);
    const trempToken = getToken();
    const dispatch = useDispatch();
   


    useEffect(() => {
        dispatch(setRoute(trempRoutes));
        dispatch(setToken(trempToken));
        if (trempToken.length > 5) {
            dispatch(setLogin(true));
        }
        axios.defaults.headers.Authorization = 'Bearer ' + trempToken;
        const pages = activeRoutes.filter((item) => item.route === window.location.pathname);


        if (pages.length == 0) {
            window.location.replace('/');
            //  dispatch(setDialogMessage({ status: 'error', message: 'This page under construction' }));
        }
    }, []);





    return (
        <BrowserRouter>
            <Routes>
                {activeRoutes.map((item, index) =>
                    <Route
                        key={item.name}
                        path={item.route}
                        element={<Pages route={item.route} />}
                    />
                )
                }
            </Routes>
        </BrowserRouter>
    );
}

export default Main;
