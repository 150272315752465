import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, IconButton } from '@mui/material';
import Icons from './Icons';
import RightSidebar from './RightSidebar';
import LeftSidebarMobile from './LeftSidebarMobile';
import Profile from './Profile';


const CustomeAppbar = styled(AppBar)(({ leftWidth }) => ({
  position: 'relative',
  backgroundColor: 'white',
  color: 'black',
  width: window.innerWidth - leftWidth
}));

export default function NavbarMobile() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const profileOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const [leftSidebarState, setLeftSidebarState] = React.useState(false);
  const [rightSidebarState, setRightSidebarState] = React.useState(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomeAppbar >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => setLeftSidebarState(true)}
          >
            <Icons icon="MenuIcon" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Box>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Icons icon="AccountCircle" />
            </IconButton>

            <IconButton
              size="large"
              edge="end"
              aria-label="setting"
              aria-haspopup="true"
              onClick={() => setRightSidebarState(true)}
              color="inherit"
            >
              <Icons icon="SettingsOutlined" />
            </IconButton>
          </Box>
        </Toolbar>
      </CustomeAppbar>
      <Profile menuId={menuId} anchorEl={anchorEl} profileOpen={profileOpen} handleMenuClose={handleMenuClose} />
      <RightSidebar state={rightSidebarState} setState={setRightSidebarState} />
      <LeftSidebarMobile state={leftSidebarState} setState={setLeftSidebarState} />
    </Box>
  );
}