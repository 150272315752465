import React from "react";
import useWindowSize from '../../Utlity/useWindowSize';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

const Layout = () => {
    return (
        <>
            {useWindowSize().desktop && <Desktop />}
            {useWindowSize().mobile && <Mobile />}
        </>
    );
}

export { Layout };
