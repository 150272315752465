import React from "react";
import { Box, CssBaseline } from '@mui/material';
import Main from './components/Main';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { blue, orange } from '@mui/material/colors';
import Dialog from './Utlity/Dialog';
import DialogLoader from './Utlity/DialogLoader';
import DialogLogin from './Utlity/DialogLogin';
import aafColor from '../src/Utlity/aafColor'
import AlertDailog from "./Utlity/alert/AlertDailog";
import bg_accounts from './assets/images/bg_accounting.png';


const theme = createTheme({
  palette: {
    primary: {
      main: aafColor[900],
      ornage: '#FF5100',
      Cdark: '#11101d',
      white: '#ffffff',
      semiDark: '#e4e4e4'
      // contrastText: '#111',
    },
    secondary: {
      main: aafColor[500],

      // contrastText: '#111',
    },
    text: {
      main: '#f4f4f4',
      primary: "#111"
    },
    chip: {
      success: '#00800034',
      error: '#ff00002d'

    },
    avatar: {
      ornage: '#ff5100a9'
    }
  },
});


function App() {
  return (
    <Box style={{ backgroundColor: '#d8feff', height: "100vh", background: 'url(' + bg_accounts + ') center center no-repeat', backgroundSize: 'center' }} >
      <ThemeProvider theme={theme}>
        <Main />
        {/* <DialogLogin /> */}
        <Dialog />
        <AlertDailog />
        <DialogLoader />
      </ThemeProvider>
      <CssBaseline />
    </Box>
  );
}

export default App;


// import { useDispatch, useSelector } from "react-redux";
// import { fetchTodos } from "./slice/todo";

// function App() {
//   const dispatch = useDispatch();
//   const state = useSelector((state) => state);

//   console.log("State", state);

//   if (state.todo.isLoading) {
//     return <h1>Loading....</h1>;
//   }

//   return (
//     <div className="App">
//       <button onClick={(e) => dispatch(fetchTodos())}>Fetch Todos</button>
//       {state.todo.data && state.todo.data.map((e) => <li>{e.title}</li>)}
//     </div>
//   );
// }

// export default App;