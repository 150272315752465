import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Link, CircularProgress, Modal, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText, ListItemButton, Divider, Menu, Card, CardContent, IconButton, FormControl, InputLabel, Select, MenuItem, ButtonGroup, Tooltip, Accordion, AccordionSummary, AccordionDetails, Tab, Tabs, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icons from '../../components/Master/Icons';
import { SettingsSystemDaydreamRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { addSearchModel, delSearchModel } from '../../slice/searchBooking';
import arrow_right from '../../assets/images/arrow_right.png';

const SearchBulider = ({ item, i }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <>
            <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
                <Box display="flex" alignItems="center" justifyContent="start">
                    <Box width="60px" sx={{ borderRadius: "8px", m: 1 }} >
                        <img width="50px" src={item.airImg} />
                    </Box>
                    <Box width="200px">
                        <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.airName}</Typography>
                    </Box>
                    <Box width="60px" >
                        <LocationBuilder location={item.startLocation.airPort} time={item.startLocation.time} />
                    </Box>
                    <Box width="100px" display="flex" alignItems="center" justifyContent="center" sx={{ position: "relative", width: "120px", height: "50px" }}>
                        <img width="110px" src={arrow_right} />
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-evenly" sx={{ position: "absolute", width: "100px", height: "50px", backgroundColor: 'transparent' }} >
                            <Typography variant="caption" sx={{ fontSize: "0.5rem", p: 0, m: 0 }}>{item.layover.length == 0 ? "Non Stop" : item.layover.length.toString() + (item.layover.length > 1 ? " Stops" : " Stop")} </Typography>
                            <Box display="flex" flexDirection="row" >
                                {item.layover && item.layover.map((lay, layIndex) =>
                                    <LayoverBuilder layover={lay} />
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box width="60px" >
                        <LocationBuilder location={item.endLocation.airPort} time={item.endLocation.time} />
                    </Box>
                    <Box width="60px" display="flex" flexDirection="column" sx={{ m: 1 }}>
                        <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.totalTime}</Typography>
                    </Box>
                    <Box width="100px" display="flex" flexDirection="column" sx={{ m: 1 }}>
                        <Typography variant="caption" sx={{ textDecoration: "line-through", color: 'red' }}>{item.currency} {item.fairRegular}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: "1rem" }} >{item.currency} {item.fairReduce}</Typography>
                    </Box>
                    <Box width="100px" display="flex" flexDirection="column" sx={{ m: 1 }}>
                        <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }}>{item.flightNo}</Typography>
                    </Box>
                    <Box width="100px" display="flex" flexDirection="column" sx={{ m: 1 }}>
                        <ButtonGroup size="small" orientation="vertical" aria-label="small outlined button group">
                            <Button endIcon={<Icons icon="ArrowForwardIcon" />} onClick={() => navigate("/booking")}  >Select</Button>
                        </ButtonGroup>
                    </Box>
                </Box>
                <FlightDetailsBuilder item={item} />
            </Box>
        </>
    );
}

const LocationBuilder = ({ location, time, date, alignItems = "start" }) => {
    return (
        <Box display="flex" flexDirection="column" alignItems={alignItems} sx={{ m: 1 }}>
            <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{time}</Typography>
            {date && <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{date}</Typography>}
            <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 'bold', fontSize: "1rem" }}>{location}</Typography>
        </Box>
    );
}

const LayoverBuilder = ({ layover }) => {
    return (
        <Tooltip title={layover.description} slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -8],
                        },
                    },
                ],
            },
        }} placement="top" arrow>
            <IconButton>
                <Box display="flex" flexDirection="column">
                    <Icons icon="LocationOnIcon" />
                    <Typography variant="caption" sx={{ fontSize: '0.5rem' }}>{layover.airPort}</Typography>
                </Box>
            </IconButton>

        </Tooltip>
    );
}

const FlightDetailsBuilder = ({ item, flexDirection = "row" }) => {
    return (
        <Box  >
            <Accordion sx={{ borderTop: "1px solid #ccc" }}>
                <AccordionSummary
                    sx={{ flexDirection: 'row-reverse' }}
                    expandIcon={<Icons icon="ExpandMore" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>  Flight Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box display="flex" flexDirection={flexDirection} sx={{ width: "100%" }} >
                        <Box display="flex">
                            <ScheduleBuilder item={item} />
                        </Box>
                        <Box display="flex">
                            <TabsBuilder item={item} />
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

const TabsBuilder = ({ item }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', height: "400px" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs sx={{ p: 1, m: 0, }} value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Baggage" />
                        <Tab label="Fare" />
                        <Tab label="Policy" />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0} sx={{ p: 0, m: 0 }} >
                    <Table sx={{ p: 0, m: 0 }} aria-label="simple table">
                        <TableHead>
                            <TableRow >
                                <TableCell sx={{ fontWeight: "bold" }}>Flight</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Cabin</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} >Check-in</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[1, 2, 3].map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        DAC - DOH
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        7 Kg
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        2 pieces
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1} sx={{ p: 0, m: 0 }}>
                    <Table sx={{ p: 0, m: 0 }} aria-label="simple table">
                        <TableHead>
                            <TableRow >
                                <TableCell sx={{ fontWeight: "bold" }}>Fare Summary</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Base Fare</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} >Tax</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[1].map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        Adult x 1
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        BDT 216,288
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        BDT 21,975
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Box height={10}></Box>
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px", bgcolor: '#ccc' }}>
                        <Typography sx={{ fontWeight: "bold" }} >DAC-LGA</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start" sx={{ width: "100%", p: 1 }}>
                        <Typography variant="caption" sx={{ width: "100%", borderBottom: "1px solid #ccc" }}>Cancellation</Typography>
                        <Typography variant="caption" >Cancellation Fee = Airline's Fee + Comapny Fee</Typography>
                        <Typography variant="caption" >Refund Amount = Paid Amount - Cancellation Fee</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start" sx={{ width: "100%", p: 1 }}>
                        <Typography variant="caption" sx={{ width: "100%", borderBottom: "1px solid #ccc" }}>Re-issue</Typography>
                        <Typography variant="caption" >Re-issue Fee = Airline's Fee + Fare Difference + Company Fee</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start" sx={{ width: "100%", p: 1 }}>
                        <Typography variant="caption" >*The airline's fee is indicative and per person. Convenience fee is non-refundable.</Typography>
                    </Box>
                </CustomTabPanel>
            </Box >
            <Box height={10}></Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "100%", height: "40px", bgcolor: '#fee', p: 1 }}>
                <Typography>Total (1 Traveler)</Typography>
                <Typography sx={{ fontWeight: "bold" }} >BDT 5,400</Typography>
            </Box>
        </Box >
    );
}

const ScheduleBuilder = ({ item }) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "40px", bgcolor: '#fee', p: 1 }}>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>DAC - EWR</Typography>
            </Box>
            {[1, 2].map((itemItem, indexItem) =>
                <Box>
                    <Box display="flex" justifyContent="start" alignItems="center" sx={{ px: 1, borderBottom: "1px solid #ccc" }}>
                        <Box width="60px" sx={{ borderRadius: "8px", m: 1 }} >
                            <img width="50px" src={item.airImg} />
                        </Box>
                        <Box width="240px" display="flex" flexDirection="column" sx={{ m: 1 }}>
                            <Typography variant="caption" >{item.airName}</Typography>
                            <Typography variant="caption" >AI 238 | Airbus 32A</Typography>
                        </Box>
                        <Box width="100px" display="flex" flexDirection="column" sx={{ m: 1 }}>
                            <Typography variant="caption" >(Economy)</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box display="flex" justifyContent="start" alignItems="center">
                            <Box width="180px" >
                                <LocationBuilder location={item.startLocation.airPort} time={item.startLocation.time} date={"Wed, 03 Jan, 2024"} />
                            </Box>
                            <Box width="120px" display="flex" alignItems="center" justifyContent="center" sx={{ position: "relative", width: "120px", height: "50px" }}>
                                <img width="110px" src={arrow_right} />
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-evenly" sx={{ position: "absolute", width: "100px", height: "50px", backgroundColor: 'transparent' }} >
                                    <Typography variant="caption" sx={{ fontSize: "0.5rem", pt: 3, m: 0 }}>2h 45m</Typography>
                                </Box>
                            </Box>
                            <Box width="180px" >
                                <LocationBuilder alignItems="end" location={item.endLocation.airPort} time={item.endLocation.time} date={"Wed, 03 Jan, 2024"} />
                            </Box>
                        </Box>
                        {indexItem != 1 && < Box display="flex" justifyContent="space-between" alignItems="center" sx={{ height: "40px", bgcolor: '#fee', p: 1 }}>
                            <Typography variant="caption">Change plane at DEL | 2h 50m layover</Typography>
                        </Box>}
                    </Box>
                </Box>)
            }
        </Box >
    );
}


const SearchBuliderMobile = ({ item, i }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <>
            <Box display="flex" flexDirection="column" sx={{ width: "100%", px: 1 }}>
                <Box display="flex" alignItems="center" justifyContent="start">
                    <img width="50px" src={item.airImg} />
                    <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: "bold" }}>{item.airName}</Typography>
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box width="60px" >
                        <LocationBuilder location={item.startLocation.airPort} time={item.startLocation.time} />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%", position: "relative", width: "120px", height: "50px" }}>
                        <img width="110px" src={arrow_right} />
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-evenly" sx={{ position: "absolute", width: "100px", height: "50px", backgroundColor: 'transparent' }} >
                            <Typography variant="caption" sx={{ fontSize: "0.5rem", p: 0, m: 0 }}>{item.layover.length == 0 ? "Non Stop" : item.layover.length.toString() + (item.layover.length > 1 ? " Stops" : " Stop")} </Typography>
                            <Box display="flex" flexDirection="row" >
                                {item.layover && item.layover.map((lay, layIndex) =>
                                    <LayoverBuilder layover={lay} />
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box width="60px" >
                        <LocationBuilder location={item.endLocation.airPort} time={item.endLocation.time} />
                    </Box>
                    <Box width="60px" display="flex" flexDirection="column" sx={{ m: 1 }}>
                        <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.totalTime}</Typography>
                    </Box>
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="caption" sx={{ textDecoration: "line-through", color: 'red' }}>{item.currency} {item.fairRegular}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: "1rem" }} >{item.currency} {item.fairReduce}</Typography>
                    <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }}>{item.flightNo}</Typography>
                    <ButtonGroup size="small" orientation="vertical" aria-label="small outlined button group">
                        <Button endIcon={<Icons icon="ArrowForwardIcon" />} onClick={() => navigate("/booking")}  >Select</Button>
                    </ButtonGroup>
                </Box>
                <Box height={10} ></Box>
                <FlightDetailsBuilder item={item} flexDirection='column' />
                <Box height={10} ></Box>
            </Box >
        </>
    );
}

export { SearchBulider, SearchBuliderMobile, LocationBuilder };
