import React, { useContext, useState } from 'react';
import { Box, Button, TextField, Typography, Link, CircularProgress, Modal, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText, ListItemButton, Divider, Menu, Card, CardContent } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';


const SelectAutocomplete = ({ width, widthList, list, model, setModel, index }) => {
    const dispatch = useDispatch();
    const [modelList, setModelList] = React.useState(list);
    // const [modelData, setModelData] = React.useState(model);
    // const modelData= model;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const profileOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSearchValue = (data) => {
        const tempList = list.filter(x => x.code.toLocaleLowerCase().match(data.toLocaleLowerCase()) || x.name.toLocaleLowerCase().match(data.toLocaleLowerCase()));
        setModelList(tempList);
    };

    const handleSelectValue = (item) => {
        dispatch(setModel({ index: index, data: { lebel: model.lebel, code: item.code, name: item.name } }));
        handleMenuClose();
    };

    return (
        <Box >
            <List sx={{ width: width, p: 0, m: 0, whiteSpace: 'nowrap' }} >
                <ListItemButton
                    onClick={handleProfileMenuOpen}>
                    <ListItemText secondary={
                        <React.Fragment>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }}>{model.lebel}</Typography>
                                <Typography variant="button" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: "bold" }} >{model.code}</Typography>
                                <Typography variant='caption' sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }} >{model.name}</Typography>
                            </Box>
                        </React.Fragment>
                    } />
                </ListItemButton>
            </List>
            <Menu elevation={3}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                id={"menuId"}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={profileOpen}
                onClose={handleMenuClose}
                sx={{ top: 90 }}
            >
                <Card elevation={0} sx={{ width: widthList }} padding={8}>
                    <Box display="flex" justifyContent="center" padding={2} >
                        <TextField type={"text"} label="Search"
                            // value={model.searchValue}
                            onChange={(event) => handleSearchValue(event.target.value)}
                            placeholder="Type to search" variant="outlined" fullWidth />
                    </Box>
                    <CardContent sx={{ padding: 0, height: "250px", overflow: "auto" }}>
                        <List sx={{ width: widthList - 20, bgcolor: 'background.paper', p: 0, m: 0, whiteSpace: 'nowrap' }}>
                            {modelList.map((item, index) =>
                                <ListItemButton key={index} onClick={() => handleSelectValue(item)} >
                                    <ListItemText sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }} primary={item.code} secondary={item.name} />
                                     {item.airPort}
                                </ListItemButton>)}
                        </List>
                    </CardContent>
                </Card>
            </Menu>
        </Box >
    );
}


const LabelButton = ({ width, model, setModel, isBold }) => {
    return (
        <Box >
            <List sx={{ width: width, p: 0, m: 0, whiteSpace: 'nowrap' }} >
                <ListItemButton onClick={setModel}>
                    <ListItemText secondary={
                        <React.Fragment>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="button" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: isBold ? "none" : "bold" }} >{model.code}</Typography>
                                <Typography variant='caption' sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: isBold ? "none" : "bold" }} >{model.name}</Typography>
                            </Box>
                        </React.Fragment>
                    } />
                </ListItemButton>
            </List>
        </Box >
    );
}


export { SelectAutocomplete, LabelButton };
