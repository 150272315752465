import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setLoader, setCircularLoader, setNotification, setDialogMessage } from '../../globalSlice'

const useSendRequest = (url = null) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            reloadData()
        }, 0);
    }, []);

    /**
     * reload data
     */
    const reloadData = () => {
        return url && sendGetRequest()
    }

    /**
     * all laoder off
     */
    const buffer = (status) => {

        setLoading(status);
        dispatch(setLoader(status))
        !status && dispatch(setCircularLoader(status))


    }

    /**
     * get request sent
     */
    const sendGetRequest = async () => {
        dispatch(setLoader(true))
        console.log('loading ON')
        buffer(true);


        let resData = await axios.get(url);
        console.log('custome hook data____', resData.data)
        setResponse(resData.data);
        buffer(false);
        console.log('loading OFF', resData)

    };


    /**
     * post request sent
    */
    const sendPostRequest = (url, data) => {
        buffer(true)

        return new Promise((resolve, reject) => {

            // const timeout = setTimeout(() => {


            axios.post(url, data)
                .then(response => {
                    // setLoading(false);
                    dispatch(setNotification({ message: response.data }))
                    reloadData()
                    resolve(response.data);
                })
                .catch(error => {
                    dispatch(setNotification({ type: 'error', message: error.response.data }))
                    buffer(false)
                    console.log('post error__hook')
                    // setLoading(false);
                    reject(error);
                });


            // }, 5000);
            // return () => clearTimeout(timeout);


        })

    };

    /**
     * put request sent 
     */
    const sendPutRequest = (url, data) => {
        dispatch(setLoader(true))
        buffer(true);
        return new Promise((resolve, reject) => {

            // const timeout = setTimeout(() => {


            axios.put(url, data)
                .then(response => {
                    // dispatch(setLoader(false))
                    // buffer(false);
                    dispatch(setNotification({ message: response.data }))
                    reloadData()
                    resolve(response.data);
                })
                .catch(error => {
                    dispatch(setDialogMessage({ status: 'error', message: error.response.data }));
                    dispatch(setLoader(false))
                    buffer(false);
                    reject(error);
                });


            // }, 5000);
            // return () => clearTimeout(timeout);

        })
    };

    /**
     * delete request sent
     */
    const sendDeleteRequest = (url) => {
        // dispatch(setLoader(true))
        // buffer(true);
        dispatch(setCircularLoader(true))
        return new Promise((resolve, reject) => {

            // const timeout = setTimeout(() => {


            axios.delete(url)
                .then(response => {
                    // buffer(false);
                    reloadData()
                    dispatch(setNotification({ message: response.data }))
                    // dispatch(setLoader(false))
                    resolve(response.data);
                })
                .catch(error => {
                    // dispatch(setLoader(false))
                    console.log('error message', error)
                    dispatch(setNotification({ type: 'error', message: error.response.data }))
                    // dispatch(setDialogMessage({ status: 'error', message: error.response.data }));
                    buffer(false);
                    reject(error);
                });


            // }, 5000);
            // return () => clearTimeout(timeout);


        })

    };


    const sendCustomGetRequest = (url, data) => {
        setLoading(true);

        return new Promise((resolve, reject) => {

            axios.get(url)
                .then(response => {
                    setLoading(false);
                    resolve(response.data);
                })
                .catch(error => {
                    console.log('custome get')
                    setLoading(false);
                    reject(error);
                });

        })

    };

    return { sendCustomGetRequest, sendGetRequest, sendPostRequest, sendPutRequest, sendDeleteRequest, response, loading, error };
};

export default useSendRequest;
