
import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Link, CircularProgress, Modal, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText, ListItemButton, Divider, Menu, Card, CardContent, IconButton, FormControl, InputLabel, Select, MenuItem, ButtonGroup, Tooltip, Accordion, AccordionSummary, AccordionDetails, Tab, Tabs, Table, TableHead, TableRow, TableCell, TableBody, Avatar, Autocomplete } from "@mui/material";
import Icons from '../../components/Master/Icons';
import { LocationBuilder } from '../FlightSelection/SearchBulider';
import arrow_right from '../../assets/images/arrow_right.png';
import infoImg from '../../assets/images/icon-info-light.svg';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from 'react-router-dom';


const FlightDetailsBuilder = ({ item }) => {

    const navigate = useNavigate();
    const hendleLogin = () => {
        navigate("/login");
    }

    return (
        <Box width={"1000px"} display="flex">
            <Box width={"600px"}  >
                <Accordion >
                    <AccordionSummary sx={{ borderBottom: "1px solid #ccc" }}
                        expandIcon={<Avatar sx={{ width: 24, height: 24 }}><Icons icon="ExpandMore" /> </Avatar>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography> DAC - EWR </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ScheduleBuilder item={item} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary sx={{ borderBottom: "1px solid #ccc" }}
                        expandIcon={<Avatar sx={{ width: 24, height: 24 }}><Icons icon="ExpandMore" /> </Avatar>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Flight Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TabsBuilder item={item} />
                    </AccordionDetails>
                </Accordion>
                {["Passenger 1", "Passenger 2", "Passenger 3"].map((item, i) =>
                    <Accordion defaultExpanded={i == 0 ? true : false} >
                        <AccordionSummary sx={{ borderBottom: "1px solid #ccc" }}
                            expandIcon={<Avatar sx={{ width: 24, height: 24 }}><Icons icon="ExpandMore" /> </Avatar>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Box display="flex" justifyContent="start" alignItems="center">
                                <Typography sx={{ fontWeight: 'bold' }}> {item} </Typography>
                                <Button variant="filledTonal" color="primary" sx={{ bgcolor: "#ccc", mx: 1, textTransform: 'none' }} >
                                    {i == 0 ? "Audlt" : i == 1 ? "Child" : "Infant"}
                                </Button>
                                {i == 0 && <Typography variant="caption" > Primary Contact </Typography>}
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <PersonalInfoBuilder item={item} />
                        </AccordionDetails>
                    </Accordion>)}

                <Box display="flex" justifyContent="center" alignItems="center" height={"50px"}>
                    <Button component="label" variant="contained" onClick={hendleLogin} >
                        Countinue
                    </Button>
                </Box>
            </Box>
            <Box width={"10px"} />
            <Box width={"390px"}>
                <Accordion defaultExpanded>
                    <AccordionSummary sx={{ borderBottom: "1px solid #ccc" }}
                        expandIcon={<Avatar sx={{ width: 24, height: 24 }}><Icons icon="ExpandMore" /> </Avatar>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="caption" sx={{ fontSize: '20px', fontWeight: "bold" }}>DAC - EWR </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PaymentBuilder item={item} />
                    </AccordionDetails>
                </Accordion>

            </Box>
        </Box >
    );
}

const FlightDetailsBuilderMobile = ({ item }) => {

    const navigate = useNavigate();
    const hendleLogin = () => {
        navigate("/login");
    }

    return (
        <Box display="flex" flexDirection={"column"} sx={{ width: "100%", p: 1 }} >
            <Accordion >
                <AccordionSummary sx={{ borderBottom: "1px solid #ccc" }}
                    expandIcon={<Avatar sx={{ width: 24, height: 24 }}><Icons icon="ExpandMore" /> </Avatar>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography> DAC - EWR </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ScheduleBuilder item={item} />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary sx={{ borderBottom: "1px solid #ccc" }}
                    expandIcon={<Avatar sx={{ width: 24, height: 24 }}><Icons icon="ExpandMore" /> </Avatar>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Flight Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TabsBuilder item={item} />
                </AccordionDetails>
            </Accordion>
            {["Passenger 1", "Passenger 2", "Passenger 3"].map((item, i) =>
                <Accordion defaultExpanded={i == 0 ? true : false} >
                    <AccordionSummary sx={{ borderBottom: "1px solid #ccc" }}
                        expandIcon={<Avatar sx={{ width: 24, height: 24 }}><Icons icon="ExpandMore" /> </Avatar>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Box display="flex" justifyContent="start" alignItems="center">
                            <Typography sx={{ fontWeight: 'bold' }}> {item} </Typography>
                            <Button variant="filledTonal" color="primary" sx={{ bgcolor: "#ccc", mx: 1, textTransform: 'none' }} >
                                {i == 0 ? "Audlt" : i == 1 ? "Child" : "Infant"}
                            </Button>
                            {i == 0 && <Typography variant="caption" > Primary Contact </Typography>}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PersonalInfoBuilderMobile item={item} />
                    </AccordionDetails>
                </Accordion>)}

            <Accordion defaultExpanded>
                <AccordionSummary sx={{ borderBottom: "1px solid #ccc" }}
                    expandIcon={<Avatar sx={{ width: 24, height: 24 }}><Icons icon="ExpandMore" /> </Avatar>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="caption" sx={{ fontSize: '20px', fontWeight: "bold" }}>DAC - EWR </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <PaymentBuilder item={item} />
                </AccordionDetails>
            </Accordion>

            <Box display="flex" justifyContent="center" alignItems="center" height={"50px"}>
                <Button component="label" variant="contained" onClick={hendleLogin} >
                    Countinue
                </Button>
            </Box>
        </Box>
    );
}

const ScheduleBuilder = ({ item }) => {
    return (
        <Box>
            {[1, 2].map((itemItem, indexItem) =>
                <Box>
                    <Box display="flex" justifyContent="start" alignItems="center" sx={{ px: 1, borderBottom: "1px solid #ccc" }}>
                        <Box width="60px" sx={{ borderRadius: "8px" }} >
                            <img width="50px" src={item.airImg} />
                        </Box>
                        <Box width="140px" display="flex" flexDirection="column" sx={{ m: 1 }}>
                            <Typography variant="caption" >{item.airName}</Typography>
                            <Typography variant="caption" >AI 238 | Airbus 32A</Typography>
                        </Box>
                        <Box width="100px" display="flex" flexDirection="column" sx={{ m: 1 }}>
                            <Typography variant="caption" >(Economy)</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box display="flex" justifyContent="start" alignItems="center">
                            <Box width="100px" >
                                <LocationBuilder location={item.startLocation.airPort} time={item.startLocation.time} date={"Wed, 03 Jan, 2024"} />
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="center" sx={{ position: "relative", width: "150px", height: "50px" }}>
                                <img width="110px" src={arrow_right} />
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-evenly" sx={{ position: "absolute", width: "100px", height: "50px", backgroundColor: 'transparent' }} >
                                    <Typography variant="caption" sx={{ fontSize: "0.5rem", pt: 3, m: 0 }}>2h 45m</Typography>
                                </Box>
                            </Box>
                            <Box width="100px" >
                                <LocationBuilder alignItems="end" location={item.endLocation.airPort} time={item.endLocation.time} date={"Wed, 03 Jan, 2024"} />
                            </Box>
                        </Box>
                        {indexItem != 1 &&
                            < Box display="flex" justifyContent="space-between" alignItems="center" sx={{ height: "40px", bgcolor: '#fee', p: 1 }}>
                                <Typography variant="caption">Change plane at DEL | 2h 50m layover</Typography>
                            </Box>}
                    </Box>
                </Box>)
            }
        </Box >
    );
}

const TabsBuilder = ({ item }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    return (
        <Box>
            <Box sx={{ width: '100%', height: "400px" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Baggage" />
                        <Tab label="Fare" />
                        <Tab label="Policy" />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow >
                                <TableCell sx={{ fontWeight: "bold" }}>Flight</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Cabin</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} >Check-in</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[1, 2, 3].map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        DAC - DOH
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        7 Kg
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        2 pieces
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Table  aria-label="simple table">
                        <TableHead>
                            <TableRow >
                                <TableCell sx={{ fontWeight: "bold" }}>Fare Summary</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Base Fare</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} >Tax</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[1].map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        Adult x 1
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        BDT 216,288
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        BDT 21,975
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{  height: "40px", bgcolor: '#ccc', p: 1 }}>
                        <Typography sx={{ fontWeight: "bold" }} >DAC-LGA</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start" sx={{  p: 1 }}>
                        <Typography variant="caption" width="100%" sx={{ borderBottom: "1px solid #ccc" }}>Cancellation</Typography>
                        <Typography variant="caption" >Cancellation Fee = Airline's Fee + Comapny Fee</Typography>
                        <Typography variant="caption" >Refund Amount = Paid Amount - Cancellation Fee</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start" sx={{  p: 1 }}>
                        <Typography variant="caption" width="100%" sx={{ borderBottom: "1px solid #ccc" }}>Re-issue</Typography>
                        <Typography variant="caption" >Re-issue Fee = Airline's Fee + Fare Difference + Company Fee</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start" sx={{ p: 1 }}>
                        <Typography variant="caption" >*The airline's fee is indicative and per person. Convenience fee is non-refundable.</Typography>
                    </Box>
                </CustomTabPanel>
            </Box >
        </Box >
    );
}

const PaymentBuilder = ({ item }) => {
    return (
        <Box sx={{ width: '100%', bgcolor: '#fff' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ height: "40px", bgcolor: '#fee', p: 1 }}>
                <Box width="32px" sx={{ borderRadius: "4px" }} >
                    <img width="32px" src={item.airImg} />
                </Box>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>DAC - EWR </Typography>
                <Typography variant="caption">One Way</Typography>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ height: "40px" }}>
                <Typography variant="caption" sx={{ fontSize: '16px', fontWeight: "bold" }}>Fare Summary </Typography>
            </Box>

            {["Adult (1 traveler)", "Child (1 traveler)", "Infant (1 traveler)"].map((item) =>
                <Box sx={{ p: 1 }} >
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="caption" sx={{ fontWeight: "bold" }}>{item}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="caption">Base Fare</Typography>
                        <Typography variant="caption">BDT 4,025</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography variant="caption">Tax</Typography>
                        <Typography variant="caption">BDT 725</Typography>
                    </Box>
                </Box>
            )}
            <Divider />
            <Box sx={{ p: 1 }} >
                <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <Typography variant="caption">Sub-Total</Typography>
                    <Typography variant="caption">BDT 4,025</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <Typography variant="caption">Hot Deals AMEX1823</Typography>
                    <Typography variant="caption">BDT 4,025</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <Typography variant="caption">Convenience Charge</Typography>
                    <Typography variant="caption">BDT 725</Typography>
                </Box>
            </Box>

            <Divider />

            <Box sx={{ p: 1 }} >
                <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <Typography variant="caption">You Pay (for 3 Travelers)</Typography>
                    <Typography variant="caption">BDT 4,025</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <Typography variant="caption">You Save</Typography>
                    <Typography variant="caption">BDT 4,025</Typography>
                </Box>
            </Box>

        </Box >
    );
}


const PersonalInfoBuilder = ({ item }) => {

    const top100Films = [
        { label: 'Andorra', year: 1994 },
        { label: 'The Godfather', year: 1972 },
        { label: 'The Godfather: Part II', year: 1974 },
        { label: 'The Dark Knight', year: 2008 },
        { label: '12 Angry Men', year: 1957 },
        { label: "Schindler's List", year: 1993 },
        { label: 'Pulp Fiction', year: 1994 }
    ];

    return (
        <Box sx={{ width: '580px', bgcolor: '#fff', p: 1 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>Personal Details</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption"><img src={infoImg} /> As mentioned on your passport or government approved IDs</Typography>
            </Box>
            <Box height={"10px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Box width={"80px"}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Title</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Title"
                        >
                            <MenuItem value={10}>Mr.</MenuItem>
                            <MenuItem value={20}>Ms.</MenuItem>
                            <MenuItem value={30}>Mrs.</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box width={"190px"}>
                    <TextField type={"text"} label="First Name" placeholder="Enter First Name" variant="outlined" fullWidth />
                </Box>
                <Box width={"270px"}>
                    <TextField type={"text"} label="Last Name" placeholder="Enter Last Name" variant="outlined" fullWidth />
                </Box>
            </Box>
            <Box height={"20px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Box width={"280px"}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}  >
                        <DatePicker
                            label="Date of Birth"
                            format="DD/MM/YYYY"
                        />
                    </LocalizationProvider>
                </Box>
                <Box width={"270px"}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={top100Films}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} label="Nationality" />}
                    />
                </Box>
            </Box>
            <Box height={"20px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Box width={"270px"}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={top100Films}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} label="Passport Country" />}
                    />
                </Box>
                <Box width={"270px"}>
                    <TextField type={"text"} label="Frequent Flyer Number (Optional)" placeholder="Enter Flyer Number" variant="outlined" fullWidth />
                </Box>
            </Box>
            <Box height={"20px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>Contact Details</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption"><img src={infoImg} /> Receive booking confirmation & updates</Typography>
            </Box>
            <Box height={"10px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Box width={"270px"}>
                    <TextField type={"text"} label="Email" placeholder="Enter Your Email" variant="outlined" fullWidth />
                </Box>
                <Box width={"270px"}>
                    <TextField type={"text"} label="Phone Number" placeholder="Enter Phone Number" variant="outlined" fullWidth />
                </Box>
            </Box>
            <Box height={"20px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>Passport Information</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption"><img src={infoImg} /> Passport should be valid for 6 months from the date of entry</Typography>
            </Box>
            <Box height={"10px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Box width={"270px"}>
                    <TextField type={"text"} label="Passport Number" placeholder="Enter Passport Number" variant="outlined" fullWidth />
                </Box>
                <Box width={"270px"}>
                    <TextField type={"text"} label="Passport Expiry Date" placeholder="Enter Expiry Date" variant="outlined" fullWidth />
                </Box>
            </Box>
            <Box height={"10px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption">Photo of Passport</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "270px", height: "270px", bgcolor: "#ccc" }} >
                    {/* <img src={} /> */}
                </Box>
                <Box width={"270px"}>
                    <Button component="label" variant="contained" startIcon={<Icons icon="CloudUploadIcon" />}>
                        Upload Passport
                    </Button>
                </Box>
            </Box>

        </Box>
    );
}

const PersonalInfoBuilderMobile = ({ item }) => {

    const top100Films = [
        { label: 'Andorra', year: 1994 },
        { label: 'The Godfather', year: 1972 },
        { label: 'The Godfather: Part II', year: 1974 },
        { label: 'The Dark Knight', year: 2008 },
        { label: '12 Angry Men', year: 1957 },
        { label: "Schindler's List", year: 1993 },
        { label: 'Pulp Fiction', year: 1994 }
    ];

    return (
        <Box sx={{  bgcolor: '#fff', p: 1 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>Personal Details</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption"><img src={infoImg} /> As mentioned on your passport or government approved IDs</Typography>
            </Box>
            <Box height={"10px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Box width={"80px"}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Title</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Title"
                        >
                            <MenuItem value={10}>Mr.</MenuItem>
                            <MenuItem value={20}>Ms.</MenuItem>
                            <MenuItem value={30}>Mrs.</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box width={"190px"}>
                    <TextField type={"text"} label="First Name" placeholder="Enter First Name" variant="outlined" fullWidth />
                </Box>
                <Box width={"270px"}>
                    <TextField type={"text"} label="Last Name" placeholder="Enter Last Name" variant="outlined" fullWidth />
                </Box>
            </Box>
            <Box height={"20px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Box width={"280px"}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}  >
                        <DatePicker
                            label="Date of Birth"
                            format="DD/MM/YYYY"
                        />
                    </LocalizationProvider>
                </Box>
                <Box width={"270px"}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={top100Films}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} label="Nationality" />}
                    />
                </Box>
            </Box>
            <Box height={"20px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Box width={"270px"}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={top100Films}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} label="Passport Country" />}
                    />
                </Box>
                <Box width={"270px"}>
                    <TextField type={"text"} label="Frequent Flyer Number (Optional)" placeholder="Enter Flyer Number" variant="outlined" fullWidth />
                </Box>
            </Box>
            <Box height={"20px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>Contact Details</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption"><img src={infoImg} /> Receive booking confirmation & updates</Typography>
            </Box>
            <Box height={"10px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Box width={"270px"}>
                    <TextField type={"text"} label="Email" placeholder="Enter Your Email" variant="outlined" fullWidth />
                </Box>
                <Box width={"270px"}>
                    <TextField type={"text"} label="Phone Number" placeholder="Enter Phone Number" variant="outlined" fullWidth />
                </Box>
            </Box>
            <Box height={"20px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>Passport Information</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption"><img src={infoImg} /> Passport should be valid for 6 months from the date of entry</Typography>
            </Box>
            <Box height={"10px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Box width={"270px"}>
                    <TextField type={"text"} label="Passport Number" placeholder="Enter Passport Number" variant="outlined" fullWidth />
                </Box>
                <Box width={"270px"}>
                    <TextField type={"text"} label="Passport Expiry Date" placeholder="Enter Expiry Date" variant="outlined" fullWidth />
                </Box>
            </Box>
            <Box height={"10px"} />
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="caption">Photo of Passport</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "270px", height: "270px", bgcolor: "#ccc" }} >
                    {/* <img src={} /> */}
                </Box>
                <Box width={"270px"}>
                    <Button component="label" variant="contained" startIcon={<Icons icon="CloudUploadIcon" />}>
                        Upload Passport
                    </Button>
                </Box>
            </Box>

        </Box>
    );
}



export { FlightDetailsBuilder, FlightDetailsBuilderMobile };