import React, { useContext, useState } from 'react';
import { Box, Button, TextField, Typography, Link, CircularProgress, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setLogin, setRoute, setDialogStatus, setDialogMessage } from '../../globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import bg_accounts from '../../assets/images/bg_accounting.png';
import Icons from '../Master/Icons';
import urlPath from '../../constants/path/urlPath';
import logo from '../../assets/images/logo.png';


const Desktop = () => {
    const companyInfo = useSelector((state) => state.global.companyInfo);
    const isLogin = useSelector((state) => state.global.isLogin);
    const routes = useSelector((state) => state.global.routes);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loadingLogin, setLoadingLogin] = useState(false);
    const [loginModel, setLoginModel] = useState({
        userId: "",
        errorUserId: false,
        helperTextUserId: "",
        password: "",
        errorPassword: false,
        helperTextPassword: "",
    });


    const hendleLogin = (event) => {
        navigate("/");
        return;
        
        setLoginModel({
            ...loginModel,
            errorUserId: true,
            helperTextUserId: "",
            errorPassword: false,
            helperTextPassword: "",
        });


        if (loginModel.userId.length === 0) {
            setLoginModel({
                ...loginModel,
                errorUserId: true,
                helperTextUserId: "The phone number is requried"
            });
            return;
        }

        if (loginModel.password.length === 0) {
            setLoginModel({
                ...loginModel,
                errorPassword: true,
                helperTextPassword: "The name is requried"
            });
            return;
        }

        
        setLoadingLogin(true);
        axios.post(urlPath.account.auth, {
            loginId: loginModel.userId,
            password: loginModel.password,
        }).then(function (response) {
            axios.defaults.headers.Authorization = 'Bearer ' + response.data.jwtToken;
            axios.get(urlPath.account.navigation).then(function (response1) {
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("userProfileTemplate", JSON.stringify(response1.data));

                dispatch(setRoute(response1.data));
                dispatch(setLogin(true));
                setLoadingLogin(false);
                navigate("/home");
            })
                .catch(function (error) {
                    dispatch(setDialogMessage({ status: 'error', message: error.response.data }));
                    setLoadingLogin(false);
                });
        })
            .catch(function (error) {
                console.log(error);
                dispatch(setDialogMessage({ status: 'error', message: error.response.data }));
                setLoadingLogin(false);
            });
    };

    const hendleForget = (event) => {
        navigate("/forget-password");
    };


    return (
        <>
            <Box display="flex" justifyContent="center" alignItems="center" height={"100vh"} >
                <Box width="350px" style={{ backgroundColor: '#fff', color: "#000", padding: '32px', borderRadius: '8px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px' }}  >
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box display="flex" flexDirection={"column"} style={{ backgroundColor: '#000', justifyContent: "center", alignItems: "center", borderRadius: '8px' }} width={120} >
                            <Box display="flex" style={{ backgroundColor: '#000', justifyContent: "center", alignItems: "center" }} height={100}>
                                <img src={logo} alt="" width={"100"} />
                            </Box>
                            <Typography variant="caption" color="primary" style={{ fontWeight: 'bold', backgroundColor: '#fff', width: "80px", textAlign: 'center', borderRadius: '2px' }}  >
                                {companyInfo.name}
                            </Typography>
                            <Box height="10px"></Box>
                        </Box>
                    </Box>
                    <Box flexGrow={1} height="20px"></Box>
                    <Box textAlign="center">
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}  >
                            Signup By Phone Number
                        </Typography></Box>
                    <Box flexGrow={1} height="20px"></Box>
                    <TextField type={"text"} label="Phone Number"
                        value={loginModel.userId}
                        error={loginModel.errorUserId}
                        helperText={loginModel.helperTextUserId}
                        onChange={(event) => setLoginModel({ ...loginModel, userId: event.target.value })}
                        onKeyUp={(event) => {
                            setLoginModel({
                                ...loginModel,
                                errorUserId: false,
                                helperTextUserId: "",
                            });

                            if (loginModel.userId.length < 11) {
                                setLoginModel({
                                    ...loginModel,
                                    errorUserId: true,
                                    helperTextUserId: "The Phone Number should be 11 digit"
                                });
                            }
                        }}
                        placeholder="Enter Phone Number" variant="outlined" fullWidth />

                    <Box height="20px"></Box>
                    <TextField type="text" label="Name"
                        value={loginModel.password}
                        error={loginModel.errorPassword}
                        helperText={loginModel.helperTextPassword}
                        onChange={(event) => setLoginModel({ ...loginModel, password: event.target.value })}
                        placeholder="Enter your name" variant="outlined" fullWidth />

                    <Box flexGrow={1} height="20px"></Box>

                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1}></Box>
                        {loadingLogin ? <CircularProgress /> : <Button variant="contained" color="primary" startIcon={<Icons icon="VpnKeyIcon" />} onClick={hendleLogin}>Signup</Button>}
                    </Box>
                    <Box flexGrow={1} height="10px"></Box>
                    <Box display="flex" style={{ justifyContent: "center" }} >
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', paddingRight: '5px' }}   >
                            Registered?
                        </Typography>
                        <Link href="#" onClick={() => navigate("/login")} underline="none">Login</Link>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export { Desktop };
