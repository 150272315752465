import React from "react";
import { Box } from "@mui/material";
import LeftSidebar from "./LeftSidebar";
import Navbar from "./Navbar";
const Desktop = (props) => {
    return (

        <Box display={'flex'} style={{ height: "100vh" }} >
            <LeftSidebar />
            <Box>
                <Navbar />
                {props.children}
            </Box>
        </Box>
    );
}

export { Desktop };
