import React, { useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDialogStatus, setDialogMessage } from '../globalSlice';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Button, Modal, Paper } from '@mui/material';

const DialogLader = () => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const dispatch = useDispatch();
    const dialogLoder = useSelector((state) => state.global.dialogLoder);

    return (
        <Modal
            open={dialogLoder}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Box sx={{ backgroundColor: '#11101d46', position: 'fixed', top: 0, zIndex: 10000, width: '100%', height: windowSize.current[1], display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ backgroundColor: 'primary.white', height: 80, width: 80, justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '5px', boxShadow: 2 }}>
                    <CircularProgress />
                </Box>
            </Box>
        </Modal>
    );
}

export default DialogLader;
