import { Box, Button, TextField, Typography, Link, CircularProgress, Modal, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText, ListItemButton, Divider, Menu, Card, CardContent } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Icons from './Icons';
import visaImg from '../../assets/images/icon-footer-visa.svg';
import bkashImg from '../../assets/images/icon-footer-bkash.svg';

const PaymentMethods = () => {
    return (
        <Box>
            <Typography sx={{ fontWeight: "bold" }}>Payment Methods</Typography>
            <Box display="flex" >
                <img src={visaImg} />
                <img src={bkashImg} />
            </Box>
        </Box>
    );
}


const Helpline = () => {
    return (
        <Box>
            <Typography sx={{ fontWeight: "bold" }}>Helpline</Typography>
            <Box display="flex" flexDirection={"column"} >
                <Typography variant="caption">We are Always here for you! Knock us on Messenger anytime</Typography>
                <Typography variant="caption">or Call our Hotline (10AM - 10PM)</Typography>
            </Box>
        </Box>
    );
}

const Contact = () => {
    return (
        <Box>
            <Typography sx={{ fontWeight: "bold" }} >Contact</Typography>
            <Box display="flex" flexDirection={"column"} >
                <Typography variant="caption">info@khanTravel.com</Typography>
                <Typography variant="caption">+88 09678 332211</Typography>
            </Box>
        </Box>
    );
}

const Footer = () => {
    const navigate = useNavigate();
    const hendleLogin = () => {
        navigate("/login");
    }

    return (
        <Box display="flex" height={100} justifyContent="space-between" alignItems="center" >
            <PaymentMethods />
            <Helpline />
            <Contact />
        </Box>
    );
}

const FooterMobile = () => {
    const navigate = useNavigate();
    const hendleLogin = () => {
        navigate("/login");
    }

    return (
        <Box display="flex" flexDirection={"column"} justifyContent="start" alignItems="start" sx={{ m: 1 }} >
            <PaymentMethods />
            <Box height={20}>&nbsp;</Box>
            <Helpline />
            <Box height={20}>&nbsp;</Box>
            <Contact />
        </Box>
    );
}

export { Footer, FooterMobile };


