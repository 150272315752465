import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDialogStatus, setDialogMessage } from '../globalSlice';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Button, Modal, Paper } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const Dialog = () => {

    // const dialogStatus = useSelector((state) => state.global.dialogStatus);
    const dialogMessage = useSelector((state) => state.global.dialogMessage);
    const dispatch = useDispatch();

    const color = dialogMessage.status == 'error' ? 'red' : 'green';
    const isDialogOpen = dialogMessage.message.length > 0 ? true : false;

    const hendleOK = (event) => {
        // dispatch(setDialogStatus(''));
        dispatch(setDialogMessage({ status: '', message: '' }));
    };

    const body = (
        <Box sx={style} display="flex" flexDirection="column" borderRadius={1} >
            <p id="simple-modal-title" style={{ color: `${color}` }} >{dialogMessage.message}</p>
            <Box display="flex" flexDirection="row">
                <Box flexGrow={1}></Box>
                <Button variant="contained" color="primary"  onClick={hendleOK} >OK</Button>
            </Box>
        </Box>
    );

    return (
        <Modal
            open={isDialogOpen}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>
    );
}

export default Dialog;
