import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Link, CircularProgress, Modal, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText, ListItemButton, Divider, Menu, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { setLogin, setRoute, setDialogStatus, setDialogMessage } from '../../globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addSearchModel, resetSearchModel } from '../../slice/searchBooking';
import { Appbar } from '../Master/Header';
import { FlightDetailsBuilderMobile } from './FlightDetailsBuilder'


const Mobile = () => {
    const searchResult = useSelector((state) => state.searchBooking.searchResult);
    return (
        <Box display="flex" flexDirection="column" alignItems="center"  >
            <Appbar />
            <Box height={10}>&nbsp;</Box>
            <Box height={10}>&nbsp;</Box>
            <FlightDetailsBuilderMobile item={searchResult[0]} />
            <Box height={10}>&nbsp;</Box>
        </Box>
    );
}

export { Mobile };
