import React from "react";
import { Layout as HomePage } from './Home/Layout'
import { Layout as LoginPage } from './Login/Layout'
import { Layout as ConstructionPage } from './Construction/Layout'
import { Layout as UserPage } from './User/Layout'
import { Layout as Api } from "./ApiList/Layout";

import { Layout as AccountPayable } from './FA/AccountingVoucher/AccountPayable/Layout'

import { Layout as Company } from './AM/Company/Layout'
import { Layout as Branch } from './AM/Branch/Layout'
// import WelcomePage from "./WelcomePage";

import { Layout as FlightSelection } from './FlightSelection/Layout'
import { Layout as Booking } from './Booking/Layout'
import { Layout as Registration } from './Registration/Layout'
import { Layout as Forget } from './Forget/Layout'

const Pages = ({ route }) => {
    const pages = pageList.filter((item) => item.route === route)
    return pages.length > 0 ? pages[0].page : <ConstructionPage />
}

export default Pages;


const pageList = [
    {
        name: "flightSelection",
        route: "/",
        page: <FlightSelection />
    },
    {
        name: "api",
        route: "/api",
        page: <Api />
    },
    {
        name: "login",
        route: "/login",
        page: <LoginPage />
    },
    {
        name: "registration",
        route: "/registration",
        page: <Registration />
    },
    {
        name: "forget-password",
        route: "/forget-password",
        page: <Forget />
    },
    {
        name: "sign-up",
        route: "/sign-up",
        page: <Registration />
    },
    {
        name: "Construction",
        route: "/construction",
        page: <ConstructionPage />
    },
    {
        name: "home",
        route: "/home",
        page: <HomePage />
    },
    {
        name: "user",
        route: "/user",
        page: <UserPage />
    },
    {
        name: "role",
        route: "/role",
        page: <>Role</>
    },
    {
        name: "Accounts Payable",
        route: "/accountsPayable",
        page: <AccountPayable />
    },
    {
        name: "Company",
        route: "/companyInfo",
        page: <Company />
    },
    {
        name: "Branch",
        route: "/branchInfo",
        page: <Branch />
    },
    {
        name: "FlightSelection",
        route: "/flightSelection",
        page: <FlightSelection />
    },
    {
        name: "booking",
        route: "/booking",
        page: <Booking />
    },
];

