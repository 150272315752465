import React from "react";
import useWindowSize from '../../Utlity/useWindowSize';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';
import Web from "./Web";
const Layout = (props) => {
    return (
        <>
            {/* <Web>{props.children}</Web> */}
            {useWindowSize().desktop && <Web>{props.children}</Web>}

            {useWindowSize().mobile && <Mobile>{props.children}</Mobile>}
        </>
    );
}

export { Layout };
