import React, { useState } from 'react'
import SubmenuItem from './SubmenuItem'
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import InboxIcon from '@mui/icons-material/Inbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function SideBarItem({ item }) {

    const [openStatus, setOpenStatus] = useState(false)
    const subMenuToggol = () => {
        setOpenStatus(!openStatus)
    }
    // console.log('side bar item', item)
    return (
        <>

            <List className="active" component='li'>

                {/* submenu title start */}
                <Box className="iocn-link" sx={{ backgroundColor: 'primary.main' }}>

                    {/* </List> */}

                    <ListItemButton component='a' href="#" onClick={subMenuToggol}>
                        <ListItemIcon className='bx-collection'>
                            <InboxIcon sx={{ color: 'text.main' }} />
                        </ListItemIcon>
                        <ListItemText component="span" sx={{ color: 'text.main' }} className="link_name" primary={item.name} />
                        {!openStatus ? <ExpandMore sx={{ color: 'text.main' }} /> : <ExpandLess sx={{ color: 'text.main' }} />}

                    </ListItemButton>
                </Box>

                {/* submenu title end */}

                {/* sub menu start */}
                {item.childNavigations.length > 0 &&
                    <SubmenuItem
                        openStatus={openStatus}
                        item={item.childNavigations}
                        title={item.name}
                    />
                }
                {/* sub menu end */}

            </List>
        </>
    )
}

export default SideBarItem