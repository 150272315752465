import React from "react";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopy from '@mui/icons-material/ContentCopy';
import LogoutIcon from '@mui/icons-material/Logout';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NavigationIcon from '@mui/icons-material/Navigation';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Search from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const Icons = ({ icon }) => {
    const icons = iconList.filter((item) => item.name === icon)
    return icons.length > 0 ? icons[0].icon : <KeyboardDoubleArrowRightIcon />
}

export default Icons;


const iconList = [
    {
        name: "FlightTakeoffIcon",
        icon: <FlightTakeoffIcon sx={{ fontSize: "3rem", fontWeight: "bold", color: "#fff" }} />
    },
    {
        name: "warning",
        icon: <WarningAmberIcon />
    },
    {
        name: "SaveIcon",
        icon: <SaveIcon />
    },
    {
        name: "CloseIcon",
        icon: <CloseIcon />
    },
    {
        name: "FilterListIcon",
        icon: <FilterListIcon />
    },
    {
        name: "AddIcon",
        icon: <AddIcon />
    },
    {
        name: "NavigationIcon",
        icon: <NavigationIcon />
    },
    {
        name: "EditIcon",
        icon: <EditIcon />
    },
    {
        name: "DeleteIcon",
        icon: <DeleteIcon />
    },
    {
        name: "PhotoCamera",
        icon: <PhotoCamera />
    },
    {
        name: "KeyboardDoubleArrowRightIcon",
        icon: <KeyboardDoubleArrowRightIcon />
    },
    {
        name: "KeyboardArrowRightIcon",
        icon: <KeyboardArrowRightIcon />
    },
    {
        name: "ExpandLess",
        icon: <ExpandLess />
    },
    {
        name: "ExpandMore",
        icon: <ExpandMore />
    },
    {
        name: "MenuIcon",
        icon: <MenuIcon />
    },
    {
        name: "AccountCircle",
        icon: <AccountCircle />
    },
    {
        name: "SettingsOutlined",
        icon: <SettingsOutlined />
    },
    {
        name: "InboxIcon",
        icon: <InboxIcon />
    },
    {
        name: "SearchIcon",
        icon: <SearchIcon />
    },
    {
        name: "ContentCopy",
        icon: <ContentCopy />
    },
    {
        name: "LogoutIcon",
        icon: <LogoutIcon />
    },
    {
        name: "AddCircleOutlineIcon",
        icon: <AddCircleOutlineIcon />
    },
    {
        name: "RemoveCircleOutlineIcon",
        icon: <RemoveCircleOutlineIcon />
    },
    {
        name: "Search",
        icon: <Search />
    },
    {
        name: "ArrowForwardIcon",
        icon: <ArrowForwardIcon />
    },
    {
        name: "LocationOnIcon",
        icon: <LocationOnIcon sx={{ fontSize: "1rem", fontWeight: "bold" }} />
    },
    {
        name: "VpnKeyIcon",
        icon: <VpnKeyIcon />
    },
    {
        name: "CloudUploadIcon",
        icon: <CloudUploadIcon />
    }
];

