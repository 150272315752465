import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Link, CircularProgress, Modal, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText, ListItemButton, Divider, Menu, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { setLogin, setRoute, setDialogStatus, setDialogMessage } from '../../globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addSearchModel, resetSearchModel } from '../../slice/searchBooking';
import { Header } from '../Master/Header';
import { FlightDetailsBuilder } from './FlightDetailsBuilder'

const Desktop = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const companyInfo = useSelector((state) => state.global.companyInfo);
    const isLogin = useSelector((state) => state.global.isLogin);
    const routes = useSelector((state) => state.global.routes);
    const way = useSelector((state) => state.searchBooking.way);
    const searchModel = useSelector((state) => state.searchBooking.searchModel);
    const searchResult = useSelector((state) => state.searchBooking.searchResult);
    const [cheapest, setCheapest] = React.useState("Cheapest");



    useEffect(() => {
        if (way != "multi") {
            dispatch(resetSearchModel());
        }

        if (way == "multi" && searchModel.length < 2) {
            dispatch(addSearchModel());
        }

    }, [way]);


    const hendleLogin = () => {
        navigate("/login");
    }

    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="center"  >
                <Header />
                <Box height={10}>&nbsp;</Box>
                <Box height={10}>&nbsp;</Box>
                <FlightDetailsBuilder item={searchResult[0]} />
                <Box height={10}>&nbsp;</Box>
            </Box>
        </>
    );

}

export { Desktop };
