import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Link, CircularProgress, Modal, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText, ListItemButton, Divider, Menu, Card, CardContent, IconButton, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Icons from '../../components/Master/Icons';
import { SettingsSystemDaydreamRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setTraveler, setTravelerAge, setClsssName } from '../../slice/searchBooking';


const SelectTraveler = ({ width, model, index }) => {
    const dispatch = useDispatch();
    const [height, setHeight] = React.useState(280);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const profileOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSelectValue = (data) => {
        // setModel({ ...model, code: data.code, name: data.name });
        handleMenuClose();
    };

    const handleIncrement = (data) => {
        if (data == "adults") {
            dispatch(setTraveler({ index: index, data: data, value: model.adults + 1 }));
        }
        if (data == "children") {
            dispatch(setTraveler({ index: index, data: data, value: model.children < 4 ? model.children + 1 : model.children }));
        }
        if (data == "infant") {
            dispatch(setTraveler({ index: index, data: data, value: model.infant < 4 ? model.infant + 1 : model.infant }));
        }
    };

    const handleDecrement = (data) => {
        if (data == "adults") {
            dispatch(setTraveler({ index: index, data: data, value: model.adults > 1 ? model.adults - 1 : model.adults }));
        }
        if (data == "children") {
            dispatch(setTraveler({ index: index, data: data, value: model.children > 0 ? model.children - 1 : model.children }));
        }
        if (data == "infant") {
            dispatch(setTraveler({ index: index, data: data, value: model.infant > 0 ? model.infant - 1 : model.infant }));
        }
    };

    useEffect(() => {
        if (model.children > 0) {
            setHeight(350);
        } else {
            setHeight(280);
        }
    }, [model.children]);

    return (
        <Box sx={{ width: "100%", margin: "2px", backgroundColor: '#eee', borderRadius: "8px" }} >
            <List sx={{ width: width, p: 0, m: 0, whiteSpace: 'nowrap' }} >
                <ListItemButton
                    onClick={handleProfileMenuOpen}>
                    <ListItemText secondary={
                        <React.Fragment>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }}>{model.lebel}</Typography>
                                <Typography variant="button" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: "bold" }} >{model.adults + model.children + model.infant} {model.code}</Typography>
                                <Typography variant='caption' sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }} >{model.className}</Typography>
                            </Box>
                        </React.Fragment>
                    } />
                </ListItemButton>
            </List>
            <Menu elevation={3}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                id={"menuId"}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={profileOpen}
                onClose={handleMenuClose}
                sx={{ top: 90 }}
            >
                <Card elevation={0} sx={{ width: '300px' }} padding={8}>
                    <CardContent sx={{ padding: 0, height: height, overflow: "auto" }}>
                        <List sx={{ width: width - 20, bgcolor: 'background.paper', p: 0, m: 0, whiteSpace: 'nowrap' }}>
                            <ListItem sx={{ width: 300 }}>
                                <Box display="flex" width={300} justifyContent="space-between" alignItems="start"  >
                                    <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start">
                                        <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }}>Adults</Typography>
                                        <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }} >12 years and above</Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="end"  >
                                        <IconButton aria-label="add" onClick={() => handleIncrement("adults")} >
                                            <Icons icon="AddCircleOutlineIcon" />
                                        </IconButton>
                                        <Typography variant="caption" sx={{ padding: '10px', fontWeight: "bold" }}>{model.adults}</Typography>
                                        <IconButton aria-label="delete" onClick={() => handleDecrement("adults")}>
                                            <Icons icon="RemoveCircleOutlineIcon" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </ListItem>
                            <Divider sx={{ width: 300 }} />
                            <ListItem sx={{ width: 300 }}>
                                <Box display="block" width={300}  >
                                    <Box display="flex" width="100%" justifyContent="space-between" alignItems="center"  >
                                        <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start">
                                            <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }}>Children</Typography>
                                            <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }} >2-11 years</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="center" >
                                            <IconButton aria-label="add" onClick={() => handleIncrement("children")} >
                                                <Icons icon="AddCircleOutlineIcon" />
                                            </IconButton>
                                            <Typography variant="caption" sx={{ padding: '10px', fontWeight: "bold" }}>{model.children}</Typography>
                                            <IconButton aria-label="delete" onClick={() => handleDecrement("children")} >
                                                <Icons icon="RemoveCircleOutlineIcon" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box display={model.children > 0 ? "flex" : "none"} justifyContent="start" alignItems="center" pt={2}  >
                                        {model.children > 0 && <SelectAge model={model.childrenAge[0]} index={index} indexAge={0} />}
                                        {model.children > 1 && <SelectAge model={model.childrenAge[1]} index={index} indexAge={1} />}
                                        {model.children > 2 && <SelectAge model={model.childrenAge[2]} index={index} indexAge={2} />}
                                        {model.children > 3 && <SelectAge model={model.childrenAge[3]} index={index} indexAge={3} />}
                                    </Box>

                                </Box>
                            </ListItem>
                            <Divider sx={{ width: 300 }} />
                            <ListItem sx={{ width: 300, }}>
                                <Box display="flex" width={300} alignItems="center" justifyContent="space-between"  >
                                    <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start">
                                        <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }}>Infant</Typography>
                                        <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }} >Below 2 years</Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="center" >
                                        <IconButton aria-label="add" onClick={() => handleIncrement("infant")} >
                                            <Icons icon="AddCircleOutlineIcon" />
                                        </IconButton>
                                        <Typography variant="caption" sx={{ padding: '10px', fontWeight: "bold" }}>{model.infant}</Typography>
                                        <IconButton aria-label="delete" onClick={() => handleDecrement("infant")}>
                                            <Icons icon="RemoveCircleOutlineIcon" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </ListItem>
                            <Divider sx={{ width: 300 }} />
                            <ListItem sx={{ width: 300 }}>
                                <Box display="flex" width={300} alignItems="center" justifyContent="space-between" >
                                    <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start">
                                        <Typography variant="caption" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', }}>Class</Typography>
                                        <Box display="flex"  >
                                            <RadioGroup row value={model.className} onChange={(event) => dispatch(setClsssName({ index: index, value: event.target.value }))}>
                                                <FormControlLabel value="Economy" control={<Radio />} label="Economy" />
                                                <FormControlLabel value="Business" control={<Radio />} label="Business" />
                                            </RadioGroup>
                                        </Box>
                                    </Box>
                                </Box>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Menu>
        </Box >
    );
}



const SelectAge = ({ model, index, indexAge }) => {
    const dispatch = useDispatch();
    return (
        <FormControl sx={{ width: '68px', paddingX: "1px" }} >
            <InputLabel id="demo-simple-select-label" sx={{ fontSize: "12px" }} >{model.name}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={model.code}
                label={model.name}
                onChange={(event) => dispatch(setTravelerAge({ index: index, indexAge: indexAge, value: { code: event.target.value, name: "Age" + (indexAge + 1).toString() } }))}
            >
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
            </Select>
        </FormControl>
    );
}


export { SelectTraveler };
