import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const getDateYYYYMMDD = (today = new Date()) => {
    // let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    let returnDate = yyyy + '-' + mm + '-' + dd;
    return returnDate;
}

const newSearchModel = () => {
    return {
        id: 0,
        from: { lebel: "From", code: "select", name: "select location" },
        to: { lebel: "To", code: "select", name: "select location" },
        journyDate: dayjs(getDateYYYYMMDD()),
        returnDate: dayjs(getDateYYYYMMDD()),
        adults: 0,
        traveler: {
            lebel: "Traveler, Class",
            code: "Traveler",
            className: "Economy",
            adults: 1,
            children: 0,
            infant: 0,
            childrenAge: [
                { "name": "Age1", "code": "" },
                { "name": "Age2", "code": "" },
                { "name": "Age3", "code": "" },
                { "name": "Age4", "code": "" }
            ]
        }
    };
}


const initialState = {
    way: 'one',
    searchModel: [
        newSearchModel()
    ],
    searchResult: []
}

export const searchBooking = createSlice({
    name: 'searchBooking',
    initialState,
    reducers: {
        setWay: (state, action) => {
            state.way = action.payload
        },
        setFrom: (state, action) => {
            // return {
            //     ...state,
            //     searchModel: state.searchModel.map((value, i) => {
            //         if (action.payload.index === i) {
            //             return { ...value, from: action.payload.data };
            //         }
            //     })
            // };
            state.searchModel[action.payload.index].from = action.payload.data
        },
        setTo: (state, action) => {
            // return {
            //     ...state,
            //     searchModel: state.searchModel.map((value, i) => {
            //         if (action.payload.index === i) {
            //             return { ...value, to: action.payload.data };
            //         }
            //     })
            // };

            state.searchModel[action.payload.index].to = action.payload.data
        },
        setJournyDate: (state, action) => {
            // return {
            //     ...state,
            //     searchModel: state.searchModel.map((value, i) => {
            //         if (action.payload.index === i) {
            //             return { ...value, journyDate: action.payload.data, returnDate: action.payload.data };
            //         }
            //     })
            // };
            state.searchModel[action.payload.index].journyDate = action.payload.data;
            state.searchModel[action.payload.index].returnDate = action.payload.data;
        },
        setReturnDate: (state, action) => {
            // return {
            //     ...state,
            //     searchModel: state.searchModel.map((value, i) => {
            //         if (action.payload.index === i) {
            //             return { ...value, returnDate: action.payload.data };
            //         }
            //     })
            // };
            state.searchModel[action.payload.index].returnDate = action.payload.data;
        },
        setTraveler: (state, action) => {
            // return ({
            //     ...state,
            //     searchModel: state.searchModel.map((value, i) => {
            //         if (action.payload.index === i) {
            //             return ({
            //                 ...value.traveler, adults: action.payload.data
            //             });
            //         }
            //     })
            // });

            if (action.payload.data == "adults") {
                state.searchModel[action.payload.index].traveler.adults = action.payload.value
            }

            if (action.payload.data == "children") {
                state.searchModel[action.payload.index].traveler.children = action.payload.value
            }

            if (action.payload.data == "infant") {
                state.searchModel[action.payload.index].traveler.infant = action.payload.value
            }
        },
        setTravelerAge: (state, action) => {
            // return {
            //     ...state,
            //     searchModel: state.searchModel.map((value, i) => {
            //         if (action.payload.index === i) {
            //             return {
            //                 ...value, traveler: value.traveler.map((age, ii) => {
            //                     if (action.payload.indexAge === ii) {
            //                         return { ...age, code: action.payload.data.code, name: action.payload.data.name };
            //                     }
            //                 })
            //             };
            //         }
            //     })
            // };
            state.searchModel[action.payload.index].traveler.childrenAge[action.payload.indexAge] = action.payload.value
        },
        setClsssName: (state, action) => {
            state.searchModel[action.payload.index].traveler.className = action.payload.value
        },
        addSearchModel: (state, action) => {
            state.searchModel.push(newSearchModel())
        },
        delSearchModel: (state, action) => {
            let itemList = [];
            state.searchModel.map((value, i) => {
                if (state.searchModel.length != i + 1) {
                    itemList.push(value);
                }
            });
            state.searchModel = itemList;
        },
        resetSearchModel: (state, action) => {
            let item = state.searchModel[0];
            state.searchModel = [item];
        },
        setSearchResult: (state, action) => {
            state.searchResult = action.payload;
        },
    },
})

export const { setWay, setFrom, setTo, setJournyDate, setReturnDate, setTraveler, setTravelerAge, setClsssName, addSearchModel, delSearchModel, resetSearchModel, setSearchResult } = searchBooking.actions

export default searchBooking.reducer